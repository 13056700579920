<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          size="sm"
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click="openModal"
        >
          <font-awesome-icon icon="pen" />
        </div>
      </template>
      <span>Modifier les colonnes</span>
    </v-tooltip>
    <v-dialog v-model="menu" max-width="900px" hide-overlay persistent>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Modifier colonnes appel à paiement</v-label
          >
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalUpdateColumns('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card center-text  mt-2">
          <form>
            <v-row>
              <v-col cols="1"></v-col>
              <v-col cols="4">
                Nom du colonne
              </v-col>
              <v-col cols="7">
                Valeur
              </v-col>
            </v-row>
            <template v-if="updateAll">
              <v-row>
                <v-col cols="5">
                  <v-checkbox
                    class="checked-global"
                    v-model="checkSousRegie"
                    @change="checkRequiredSousRegie"
                    color="#704ad1"
                    value="sous_regie"
                    :unchecked-value="false"
                    :false-value="false"
                    :true-value="'sous_regie'"
                    hide-details
                    label="Sous régie"
                  ></v-checkbox>
                </v-col>
                <v-col cols="7">
                  <v-select
                    placeholder="Séléctionnez"
                    :items="optionsSelectColumnSousRegie"
                    :rules="rulesSousRegie"
                    class="select-menu msg-error"
                    label=""
                    :persistent-placeholder="true"
                    v-model="sous_regie"
                    item-text="full_name"
                    item-value="id"
                    outlined
                    dense
                    :auto-focus="false"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#704ad1"
                    item-color="#704ad1"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-checkbox
                    class="checked-global"
                    v-model="checkResteaCharge"
                    @change="checkRequiredResteACharge"
                    color="#704ad1"
                    value="commercial_reste_a_charge"
                    :unchecked-value="false"
                    :false-value="false"
                    :true-value="'commercial_reste_a_charge'"
                    hide-details
                    label="Commercial reste à charge"
                  ></v-checkbox>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    label=""
                    type="number"
                    class="msg-error"
                    :persistent-placeholder="true"
                    v-model="commercial_reste_a_charge"
                    outlined
                    :rules="rulesCommercialResteCharge"
                    color="#704ad1"
                    oninput="validity.valid||(value < 0 || value.length==0? value = '' : value =value);"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-checkbox
                    class="checked-global"
                    v-model="checkAgentCommercial"
                    @change="checkRequiredAgentCommercial"
                    color="#704ad1"
                    value="agent_commercial_terrain"
                    :unchecked-value="false"
                    :false-value="false"
                    :true-value="'agent_commercial_terrain'"
                    hide-details
                    label="Agent commercial terrain"
                  ></v-checkbox>
                </v-col>
                <v-col cols="7">
                  <v-select
                    placeholder="Séléctionnez"
                    :items="optionsSelectColumnCommercial"
                    class="select-menu msg-error"
                    label=""
                    :persistent-placeholder="true"
                    v-model="agent_commercial_terrain"
                    item-text="full_name"
                    item-value="id"
                    outlined
                    :rules="rulesAgentCommercialTerrain"
                    :required="requiredAgentCommercial"
                    dense
                    :auto-focus="false"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#704ad1"
                    item-color="#704ad1"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <v-checkbox
                    class="checked-global"
                    v-model="checkStatut"
                    @change="checkRequiredStatut"
                    color="#704ad1"
                    value="statut_appel_commercial"
                    :unchecked-value="false"
                    :false-value="false"
                    :true-value="'statut_appel_commercial'"
                    hide-details
                    label="Statut appel à paiement"
                  ></v-checkbox>
                </v-col>
                <v-col cols="7">
                  <v-select
                    placeholder="Séléctionnez"
                    :items="optionsSelectStatut"
                    class="select-menu msg-error"
                    label=""
                    :persistent-placeholder="true"
                    v-model="statut_appel_commercial"
                    item-text="full_name"
                    item-value="id"
                    outlined
                    :rules="rulesStatutAppelCommercial"
                    dense
                    :auto-focus="false"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#704ad1"
                    item-color="#704ad1"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </template>
          </form>
        </v-card-text>
        <div v-if="error" class="message-error-modal ">
          <ul v-if="Array.isArray(error)" class="mb-1">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal mb-1" v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click.prevent="handleUpdateColumns"
            color="#704ad1"
            :loading="loading"
          >
            Valider
          </v-btn>
          <v-btn text @click.prevent="handleModalUpdateColumns('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <b-modal
      id="updateColumns"
      ref="updateColumns"
      title="Modifier colonnes appel à paiement"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-commercial "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier colonnes appel à paiement</div>
        <div class="iconClose" @click.prevent="hideModal('updateColumns')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleUpdateColumns"
            class="form-modal-custom-style"
          >
            <b-row>
              <b-col cols="1"></b-col>
              <b-col cols="4">
                <b-form-group
                  label="Nom du colonne"
                  label-for="month"
                  class="input-modal-champ type-doc-class"
                >
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-group label="Valeur : " label-for="valeur" class="mr-2">
                </b-form-group>
              </b-col>
            </b-row>
            <template v-if="updateAll">
              <b-row>
                <b-col cols="1">
                  <b-form-checkbox
                    v-model="checkSousRegie"
                    name="some-radios"
                    value="sous_regie"
                    class="mt-2 check-th-details"
                    @change="checkRequiredSousRegie"
                  ></b-form-checkbox>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Sous régie"
                    label-for="month"
                    class="input-modal-champ"
                  >
                  </b-form-group
                ></b-col>
                <b-col cols="7">
                  <b-form-select
                    class="b-form-select-raduis"
                    :required="requiredSousRegie"
                    v-model="sous_regie"
                    :options="optionsSelectColumnSousRegie"
                    text-field="full_name"
                    value-field="id"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="1">
                  <b-form-checkbox
                    v-model="checkResteaCharge"
                    name="some-radios"
                    value="commercial_reste_a_charge"
                    @change="checkRequiredResteACharge"
                    class="mt-2 check-th-details"
                  ></b-form-checkbox>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Commercial reste à charge"
                    label-for="month"
                    class="input-modal-champ"
                  >
                  </b-form-group>
                </b-col>
                <b-col cols="7">
                  <b-form-input
                    autocomplete="off"
                    v-model="commercial_reste_a_charge"
                    type="number"
                    step="any"
                    :required="requiredresteCharge"
                    class="b-form-input-raduis"
                    min="0"
                    oninput="validity.valid||(value < 0 || value.length==0? value = '' : value =value);"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="1">
                  <b-form-checkbox
                    v-model="checkAgentCommercial"
                    name="some-radios"
                    value="agent_commercial_terrain"
                    @change="checkRequiredAgentCommercial"
                    class="mt-2 check-th-details"
                  ></b-form-checkbox>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Agent commercial terrain"
                    label-for="month"
                    class="input-modal-champ"
                  >
                  </b-form-group>
                </b-col>
                <b-col cols="7">
                  <b-form-select
                    class="b-form-select-raduis"
                    :required="requiredAgentCommercial"
                    v-model="agent_commercial_terrain"
                    :options="optionsSelectColumnCommercial"
                    text-field="full_name"
                    value-field="id"
                  ></b-form-select>
                </b-col>
              </b-row>
            </template>
            <b-row>
              <b-col cols="1">
                <b-form-checkbox
                  v-model="checkStatut"
                  name="some-radios"
                  value="statut_appel_commercial"
                  @change="checkRequiredStatut"
                  class="mt-2 check-th-details"
                ></b-form-checkbox>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  label="Statut appel à paiement"
                  label-for="month"
                  class="input-modal-champ"
                >
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-select
                  class="b-form-select-raduis"
                  :required="requiredStatut"
                  v-model="statut_appel_commercial"
                  :options="optionsSelectStatut"
                  text-field="full_name"
                  value-field="id"
                ></b-form-select>
              </b-col>
            </b-row>

            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                type="reset"
                class="button-cancel-style ml-2"
                @click="hideModal('updateColumns')"
              >
                <span> Annuler </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'update-multiple-column-appel-paiement',
  props: {
    dataToUse: { required: true },
    generateFunction: { required: true },
    optionsSelectColumnSousRegie: { required: true },
    optionsSelectColumnCommercial: { required: true },
    optionsSelectStatut: { required: true },
    updateAll: { required: true }
  },
  data() {
    return {
      sous_regie: null,
      commercial_reste_a_charge: null,
      agent_commercial_terrain: null,
      statut_appel_commercial: null,
      rulesSousRegie: [],
      rulesCommercialResteCharge: [],
      rulesAgentCommercialTerrain: [],
      rulesStatutAppelCommercial: [],
      error: [],
      loading: false,
      checkSousRegie: null,
      checkResteaCharge: null,
      checkAgentCommercial: null,
      checkStatut: null,
      requiredSousRegie: false,
      requiredresteCharge: false,
      requiredAgentCommercial: false,
      requiredStatut: false,
      menu: false
    };
  },
  methods: {
    handleModalUpdateColumns(action) {
      if (action == 'hide') {
        this.menu = false;
      } else {
        this.menu = true;
      }
    },
    openModal() {
      this.menu = true;
    },
    checkRequiredStatut() {
      this.error = [];
      this.rulesStatutAppelCommercial =
        this.checkStatut == 'statut_appel_commercial'
          ? [v => !!v || 'Statut appel commercial est obligatoire.']
          : [];
      this.statut_appel_commercial =
        this.checkStatut == false ? null : this.statut_appel_commercial;
    },
    checkRequiredAgentCommercial() {
      this.error = [];
      this.rulesAgentCommercialTerrain =
        this.checkAgentCommercial == 'agent_commercial_terrain'
          ? [v => !!v || 'Agent commercial terrain est obligatoire.']
          : [];
      this.agent_commercial_terrain =
        this.checkAgentCommercial == false
          ? null
          : this.agent_commercial_terrain;
    },
    checkRequiredResteACharge() {
      this.error = [];
      this.rulesCommercialResteCharge =
        this.checkResteaCharge == 'commercial_reste_a_charge'
          ? [v => !!v || 'Commercial reste à charge est obligatoire.']
          : [];

      this.commercial_reste_a_charge =
        this.checkResteaCharge == false ? null : this.commercial_reste_a_charge;
    },
    checkRequiredSousRegie() {
      this.error = [];
      this.rulesSousRegie =
        this.checkSousRegie == 'sous_regie'
          ? [v => !!v || 'Sous régie est obligatoire.']
          : [];

      this.sous_regie = this.checkSousRegie == false ? null : this.sous_regie;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async handleUpdateColumns() {
      this.error = [];
      this.loading = true;
      if (
        this.checkResteaCharge != 'commercial_reste_a_charge' &&
        this.checkSousRegie != 'sous_regie' &&
        this.checkAgentCommercial != 'agent_commercial_terrain' &&
        this.checkStatut != 'statut_appel_commercial'
      ) {
        this.loading = false;
        this.error = ['Sélectionner au moins une colonne à modifier'];
      } else {
        const response = await this.generateFunction({
          ids: this.dataToUse,
          commercial_reste_a_charge: this.commercial_reste_a_charge,
          sous_regie: this.sous_regie,
          agent_commercial_terrain: this.agent_commercial_terrain,
          statut_appel_commercial: this.statut_appel_commercial
        });
        if (response.success) {
          this.loading = false;
          this.$emit('reloadData');
          this.handleModalUpdateColumns('hide');
        } else {
          this.loading = false;
          this.error = response.error;
        }
      }
    },
    resetModal() {
      this.sous_regie = null;
      this.commercial_reste_a_charge = null;
      this.agent_commercial_terrain = null;
      this.error = [];
      this.loading = false;
      this.checkSousRegie = false;
      this.checkResteaCharge = false;
      this.checkAgentCommercial = false;
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue')
  }
};
</script>
<style lang="scss">
.msg-error {
  .v-messages.theme--light {
    display: block;
  }

  .v-text-field__details {
    display: block !important;
  }
}
</style>
